.search-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100vw;
    flex-grow: 1;
  }

