.navbar {
    background: #F6F7F9;
    display: flex;
    padding: 30px 0px 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.navbar-content {
    display: flex;
    padding: 0px 75px 0px 75px;
    width: 100%;
    max-width: 1380px;
    justify-content: space-between;
    align-items: center;
}