.product-list {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-top: 45px;
    min-height: 100vh;
    gap: 0px;
  
    &__back-button {
      padding: 10px 20px;
      font-size: 1em;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 20px;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .product-list-sidebar {
    display: flex;
    padding: 0px 45px;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 35px;
  }

  .product-list-sidebar-new-button {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
  }

  .product-list-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    padding-bottom: 50px;
  }
  
  .product-list-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    align-items: start;
    width: 940px;
    gap: 20px;
  }

  .product-list-query {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    justify-content: start;

    .bp5-icon {
      padding-top: 0px;
    }

    span {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }
  }

  .product-list-more-results-button {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
