.perplexity-analysis-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: var(--text-muted, #5F6B7C);
    font-size: 14px;
}

.perplexity-analysis-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 8px;
    font-size: 16px;

    span {
        font-weight: 400;
        color: #9747FF;
    }
}

.perplexity-analysis-header-icon {
    margin-top: 3px;
}

.perplexity-analysis-card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 8px;
    
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;

    h3 {
        font-weight: 700;
        font-size: 15px;
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0 0 0 13px;
    }

    li {
        margin-top: 5px;
    }
}