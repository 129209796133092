.app {
    background: var(--background-2, #F6F7F9);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;;
}