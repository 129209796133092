

.example-typewriter {
    width: 547px;
    color: var(--text-muted, #5F6B7C);
    font-family: "PT Sans";
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%; /* 20px */
    height: 50px;
  }