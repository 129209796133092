.product-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  background-color: #fff;

  a:hover {
    text-decoration:none;
  }
}

.product-card-header {
  color: #7E8EA4;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.product-card-logo {
  height: 100%;
  margin-top: 8px;
}

.product-card-content {
  padding: 5px 20px 20px 20px;
}

.product-card-image-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}

.product-card-image {
  min-width: 150px;  // Set width to desired value
  height: 200px; // Set height to match width to create a square aspect ratio
  border-radius: 5px;
  object-fit: scale-down; // Ensure the image covers the area without distortion
}

.product-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 13px;
}

.product-card-title {
  font-size: 1.5em;
  color: #1F2C90;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: -webkit-box;        /* Use the flexbox-based display for truncation */
  -webkit-box-orient: vertical;/* Specify the orientation of the box as vertical */
  -webkit-line-clamp: 2;       /* Limit the text to 2 lines */
  overflow: hidden;            /* Hide the overflowing content */
  text-overflow: ellipsis;     /* Displays "..." for truncated text */
  max-height: 50px; 
  height: 36px;
  margin: 0px;
}

.product-card-title-tooltip {
  width: 350px;
}

.product-card-price-review {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
}

.product-card-price {
  color: #000;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-card-review {
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.product-card-review-rating {
  font-weight: 700;
}

.product-card-review-count {
  color: var(--text-muted, #5F6B7C);
}

.product-card-description {
  color: var(--text-body, #1C2127);
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  display: -webkit-box;        /* Use the flexbox-based display for truncation */
  -webkit-box-orient: vertical;/* Specify the orientation of the box as vertical */
  -webkit-line-clamp: 5;       /* Limit the text to 2 lines */
  overflow: hidden;            /* Hide the overflowing content */
  text-overflow: ellipsis;     /* Displays "..." for truncated text */
  // max-height: 4.5em; 
  margin: 0px;
  height: 83px;
}

.product-card-rating {
  margin: 10px 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-card-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  margin-top: auto;

  &:hover {
    background-color: #0056b3;
    text-decoration: none;
  }
}

.rating-gold {
  color: #D3C034;
}
.rating-silver {
  color: #9C9C9C;
}
.rating-bronze {
  color: #B68282;
}
.rating-other {
  color: #393939;
}