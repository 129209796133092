.search-bar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 700px;
  flex-grow: 1;
  height: calc(100vh - 81px);
}

.search-bar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  width: 100%;
  flex-grow: 1;
}

h1 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 40px */
  margin: 0px;
}

.search-bar-form-elements {
  display: flex;
  flex-direction: column;
  justify-items: right;
  align-items: end;
  gap: 10px;
  width: 80%;
}

.search-bar-submit-button {
  width: 80px;
  align-self: right;
}

.search-bar-input {
  padding: 10px;
  font-size: 1.2em;
  min-height: 110px;
  width: 100%;
  resize: vertical;
}
